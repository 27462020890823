import useJwt from "@/auth/jwt/useJwt";

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem("userData") &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  return { name: userRole.route };
  // if (userRole === "admin") return "/";
  // if (userRole === "client") return { name: "access-control" };
  // if (userRole === 1) return "/";
  // if (userRole === 2) return { name: "apps-pricemanagement" };
  // if (userRole === 3) return { name: "apps-font" };
  // if (userRole === 4) return { name: "apps-gift" };
  // if (userRole === 5) return { name: "apps-subcategories" };
  // if (userRole === 6) return { name: "apps-banners" };
  // if (userRole === 7) return { name: "apps-ads" };
  // if (userRole === 8) return { name: "apps-promocode" };
  // if (userRole === 9) return { name: "apps-payout" };
  // if (userRole === 10) return { name: "apps-vendor" };
  // if (userRole === 11) return { name: "apps-myuser" };
  // if (userRole === 12) return { name: "apps-requests" };
  // if (userRole === 13) return { name: "pages-profile", params: { id: 0 } };
  // if (userRole === 14) return { name: "apps-reports" };
  // if (userRole === 15) return { name: "apps-referrals" };
  // if (userRole === 16) return { name: "apps-terms" };
  // if (userRole === 17) return { name: "apps-privacy" };
  // if (userRole === 18) return { name: "auth-reset-password-v1" };
  // if (userRole === 19) return { name: "apps-advertise" };
  // if (userRole === 20) return "/";
  // if (userRole === 12) return { name: "apps-admins" };
  // return { name: "auth-login" };
};
